.stock-date input:not(:hover).bp3-input {
  box-shadow: none;
}
.stock-date input.bp3-input {
  margin-top: 2px;
  width: 100px;
}
.stock-aggregate {
  margin-top: 4px;
}
