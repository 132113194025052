.data-table th,
.data-table td {
  white-space: nowrap;
  width: 1px;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
