div.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
div#data-grid-wrapper {
  padding-top: 100px;
}
div#data-grid {
  height: 100%;
}
