.item-card .bp3-form-group.bp3-inline .bp3-label {
  width: 120px;
  text-align: right;
}
.item-card .bp3-form-group.bp3-inline .bp3-form-content .bp3-checkbox {
  margin-left: 10px;
}
.item-card
  .bp3-form-group.bp3-inline
  .bp3-form-content
  .bp3-checkbox.bp3-disabled {
  cursor: default;
}
.item-card
  .bp3-form-group.bp3-inline
  .bp3-form-content
  .bp3-checkbox.bp3-disabled
  .bp3-control-indicator {
  cursor: default;
}
